import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core/'
import AppBar from '@material-ui/core/AppBar'
import logo from "../assets/logo.png"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styled from "styled-components"

const StyledBar = styled(AppBar)`
background-color:black;
`;
const StyledNavButton = styled(Button)`
color: white;
font-family: 'Open Sans';
text-transform: none;
&& .MuiButton-label {
    text-decoration: none !important;
}
`;
const Logo = styled.img`
height: 28px;
`;
const AppBarGrid = styled.div`
display: grid;
grid-template-columns: 200px 1fr auto;
height: 64px;
padding: 16px;
`;
const NavLinks = styled.div`
font-family: 'sans, sans-serif';
display: grid;
grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
&& a, a:visited {
    color:white;
    text-decoration: none !important;
}
&& a.current {
    color:red;
}
`;
const MobileNavLinks = styled.div`
display: grid;
grid-template-rows: auto auto auto auto auto;
&& a, a:visited {
    color: white;
    text-decoration: none !important;
} 
&& a:hover {
    color: red;
    text-decoration: underline;
}
`;
const MobileMenu = styled(Menu)`
border-radius:0;
color: white;
&& .MuiMenu-paper {
    background-color: black;
    color: white;
    //padding:0;
    margin:0;
    border-radius: 0px;
    border: solid gray 1px;
}
&& .MuiMenu-list {
    padding:2px;
    margin:0;
}
`;
const Hamburger = styled(IconButton)`
margin:0;
padding:0;
`;
const StyledMenuIcon = styled(MenuIcon)`
color: red;
`;

function MobileNav() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Fragment>
            <Hamburger edge="start" aria-controls="simple-menu" aria-haspopup="true" color="inherit" aria-label="menu" onClick={handleClick}>
                <StyledMenuIcon />
            </Hamburger>
            <MobileMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MobileNavLinks>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/">Tuotteet</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/metaka165">Metaka 165</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/metaka180">Metaka 180</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/metaka200">Metaka 200</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/metaka240">Metaka 240</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/metaka240JL">Metaka 240JL</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/metaka300">Metaka 300</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/metaka320JL">Metaka 320JL</Link></MenuItem>
                    {/*<MenuItem onClick={handleClose}><Link activeClassName="current" to="/historia">Historia</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/kaytetyt">Käytetyt</Link></MenuItem>*/}
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/yhteystiedot">Yhteystiedot</Link></MenuItem>
                </MobileNavLinks>
            </MobileMenu>
        </Fragment>
    )
}
function DesktopNav() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <NavLinks>
            <StyledNavButton edge="end"><Link activeClassName="current" to="/">Etusivu</Link></StyledNavButton>
            <StyledNavButton edge="end" onClick={handleClick}>Tuotteet</StyledNavButton>
            <MobileMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            ><MobileNavLinks>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/metaka165">Metaka 165</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/metaka180">Metaka 180</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/metaka200">Metaka 200</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/metaka240">Metaka 240</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/metaka240JL">Metaka 240JL</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/metaka300">Metaka 300</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link activeClassName="current" to="/metaka320JL">Metaka 320JL</Link></MenuItem>
                </MobileNavLinks>
            </MobileMenu>
            {/* <StyledNavButton edge="end"><Link activeClassName="current" to="/metaka165">Metaka 165</Link></StyledNavButton>
            <StyledNavButton edge="end"><Link activeClassName="current" to="/metaka180">Metaka 180</Link></StyledNavButton>
            <StyledNavButton edge="end"><Link activeClassName="current" to="/metaka200">Metaka 200</Link></StyledNavButton>
            <StyledNavButton edge="end"><Link activeClassName="current" to="/metaka240">Metaka 240</Link></StyledNavButton>
            <StyledNavButton edge="end"><Link activeClassName="current" to="/metaka300">Metaka 300</Link></StyledNavButton>
            <StyledNavButton edge="end"><Link activeClassName="current" to="/metaka320">Metaka 320</Link></StyledNavButton> */}
            {/*<StyledNavButton><Link activeClassName="current" to="/historia">Historia</Link></StyledNavButton>
            <StyledNavButton><Link activeClassName="current" to="/kaytetyt">Käytetyt</Link></StyledNavButton>*/}

            <StyledNavButton><Link activeClassName="current" to="/yhteystiedot">Yhteystiedot</Link></StyledNavButton>
        </NavLinks>
    )
}
export default function Nav() {
    const breakpoints = useBreakpoint();
    return (
        <StyledBar elevation={0} position="fixed">
            <AppBarGrid>
                <Link to="/"><Logo src={logo} /></Link>
                <div></div>
                {breakpoints.sm ? <MobileNav /> : <DesktopNav />}
            </AppBarGrid>
        </StyledBar>
    )
}